// import logo from './logo.svg';
// import './App.css';
//
// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }
//
// export default App;


import React, { useState, useEffect, useRef } from 'react';

const CANVAS_SIZE = 400;
const GRID_SIZE = 20;
const SNAKE_SIZE = CANVAS_SIZE / GRID_SIZE;
const SPEED = 100;
const WIN_SCORE = 30;

const SnakeGame = () => {
  const canvasRef = useRef(null);
  const [snake, setSnake] = useState([{ x: 10, y: 10, color: '#ffffff' }]);
  const [food, setFood] = useState({ x: 5, y: 5, color: '#ff0000' });
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [won, setWon] = useState(false);

  const getRandomPosition = () => ({
    x: Math.floor(Math.random() * GRID_SIZE),
    y: Math.floor(Math.random() * GRID_SIZE),
  });

  const getRandomColor = () => `hsl(${Math.random() * 360}, 100%, 50%)`;

  const generateFood = () => {
    let newFood;
    do {
      newFood = { ...getRandomPosition(), color: getRandomColor() };
    } while (snake.some(segment => segment.x === newFood.x && segment.y === newFood.y));
    setFood(newFood);
  };

  const isValidMove = (x, y) => {
    return !snake.slice(1).some(segment => segment.x === x && segment.y === y);
  };

  const moveSnake = () => {
    setSnake(prevSnake => {
      const head = prevSnake[0];
      const possibleMoves = [
        { x: head.x + 1, y: head.y },
        { x: head.x - 1, y: head.y },
        { x: head.x, y: head.y + 1 },
        { x: head.x, y: head.y - 1 },
      ];

      // Sort moves by distance to food
      possibleMoves.sort((a, b) => {
        const distA = Math.abs(a.x - food.x) + Math.abs(a.y - food.y);
        const distB = Math.abs(b.x - food.x) + Math.abs(b.y - food.y);
        return distA - distB;
      });

      let newHead;
      for (let move of possibleMoves) {
        if (isValidMove(move.x, move.y)) {
          newHead = { ...move, color: head.color };
          break;
        }
      }

      if (!newHead) {
        // No valid moves, game over
        setGameOver(true);
        return prevSnake;
      }

      // Wrap around the edges
      newHead.x = (newHead.x + GRID_SIZE) % GRID_SIZE;
      newHead.y = (newHead.y + GRID_SIZE) % GRID_SIZE;

      const newSnake = [newHead, ...prevSnake];

      // Check if food is eaten
      if (newHead.x === food.x && newHead.y === food.y) {
        setScore(prevScore => {
          const newScore = prevScore + 1;
          if (newScore >= WIN_SCORE) {
            setWon(true);
            setGameOver(true);
          }
          return newScore;
        });
        generateFood();
        newHead.color = food.color;  // Change head color to food color
      } else {
        newSnake.pop();
      }

      return newSnake;
    });
  };

  useEffect(() => {
    if (gameOver) return;
    const gameLoop = setInterval(moveSnake, SPEED);
    return () => clearInterval(gameLoop);
  }, [gameOver, food]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, CANVAS_SIZE, CANVAS_SIZE);

    // Draw background
    ctx.fillStyle = '#1a1a1a';
    ctx.fillRect(0, 0, CANVAS_SIZE, CANVAS_SIZE);

    // Draw snake
    snake.forEach((segment) => {
      ctx.shadowBlur = 15;
      ctx.shadowColor = segment.color;
      ctx.fillStyle = segment.color;

      ctx.beginPath();
      ctx.roundRect(segment.x * SNAKE_SIZE, segment.y * SNAKE_SIZE, SNAKE_SIZE, SNAKE_SIZE, 5);
      ctx.fill();
    });

    // Draw food
    ctx.shadowBlur = 20;
    ctx.shadowColor = food.color;
    ctx.fillStyle = food.color;
    ctx.beginPath();
    ctx.arc((food.x + 0.5) * SNAKE_SIZE, (food.y + 0.5) * SNAKE_SIZE, SNAKE_SIZE / 2, 0, 2 * Math.PI);
    ctx.fill();

    // Reset shadow effect
    ctx.shadowBlur = 0;

  }, [snake, food]);

  const restartGame = () => {
    setSnake([{ x: 10, y: 10, color: '#ffffff' }]);
    generateFood();
    setGameOver(false);
    setScore(0);
    setWon(false);
  };

  return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-800">
        <h1 className="text-4xl font-bold mb-4 text-white">自动寻路炫酷贪吃蛇</h1>
        <canvas
            ref={canvasRef}
            width={CANVAS_SIZE}
            height={CANVAS_SIZE}
            className="border-4 border-white rounded-lg shadow-lg"
        />
        <p className="text-2xl mt-4 text-white">得分: {score} / {WIN_SCORE}</p>
        {gameOver && (
            <div className="mt-4">
              <p className="text-2xl text-red-500 mb-2">
                {won ? "恭喜你赢了!" : "游戏结束!"}
              </p>
              <button
                  onClick={restartGame}
                  className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300"
              >
                重新开始
              </button>
            </div>
        )}
      </div>
  );
};

export default SnakeGame;